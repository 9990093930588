.sub-line {
    color: gray;
    font-size: 1em;
}

.option-label {
    margin-left: -1em;
}

.option-radio {
    margin-right: 0.5em;
}

.question-list {
    list-style-type: none;
    /*border: 2px solid blue;*/
    /*background-color: lightblue;*/
    margin-left: -10px;
    line-height: 2em;

}

.question-vertical {
    list-style-type: none;
    display: list-item;
}

.question-horizontal {
    list-style-type: none;
    /*display: inline-block;*/
    display: list-item;
}