.cost-summary {
    /* float:right; */
    width: 40em;
    max-width: 30rem;
    /*padding: 3rem;*/
    /*margin: 2rem auto;*/
    text-align: left;
}

.cost-summary__line{
    display: flex;
    justify-content:space-between;
}
