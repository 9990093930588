.concept img {
    max-height: 10rem;
}

.chooseButton {
    width: 100%;
}

.no-select {
    user-select: none;
    -moz-user-select: none;
    -webkit-text-select: none;
    -webkit-user-select: none;
}

.no-select h3 {
    height: 3em;
}