.question-select {

    width: 40%;
    line-height: 2.5em;
    height: 2.5em;
    margin-left: 1em;
}

.sub-line {
    width: 75%;
}