.wizard {
  min-width: 1200px;
  width: 70%;
  /*max-width: 40rem;*/
  /*padding: 3rem;*/
  /*margin: 2rem auto;*/
  margin: 2rem ;
  text-align: left;
}


#concepts {
  width: 70%;
  min-width: 50rem;
/*  margin: 2rem auto;*/
  display: flex;
  justify-content: left;
  gap: 1.5rem;
/*  display: flex;*/
}


.concept {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  /* background: linear-gradient(#250358, #440c66); */
  padding: 1.5rem;
  border-radius: 6px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
  width: 33%;
  transition: all 0.2s
}

.fixedContainer {
  background-color:#ddd;
  position: fixed;
  padding: 2em;
  left: 90%;
  top: 10%;
  transform: translateX(-70%);
}

.auditLink {
  color: orangered;
}